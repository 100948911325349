<template>
  <div class="auth-container">
    <div class="auth-form register">
      <div class="auth-form__header">
        <span @click="$router.push('/auth')">{{ $t('links.entrance') }}</span>
        <span class="active-header">{{ $t('links.registration') }}</span>
      </div>
      <b-form @submit="onSubmit" class="auth-form__body">
        <AppInput
          ref="firstName"
          name="firstName"
          :label="$t('placeholders.name')"
          :invalidFeedback="getErrorMessage('firstName')"
          :state="$v.form.firstName.$error ? false : !$v.form.firstName.$invalid ? true : null"
          @blur="setField"
        />

        <AppInput
          ref="lastName"
          name="lastName"
          :label="$t('placeholders.surname')"
          :invalidFeedback="getErrorMessage('lastName')"
          :state="$v.form.lastName.$error ? false : !$v.form.lastName.$invalid ? true : null"
          @blur="setField"
        />

        <AppInput
          ref="email"
          name="email"
          :label="$t('label.email')"
          :invalidFeedback="getErrorMessage('email') || error"
          :state="$v.form.email.$error || error ? false : !$v.form.email.$invalid ? true : null"
          @blur="setField"
        />

        <AppInput
          type="password"
          ref="password"
          name="password"
          :label="$t('placeholders.createPassword')"
          :state="$v.form.password.$error ? false : !$v.form.password.$invalid ? true : null"
          @blur="setField"
        />

        <AppInput
          class="auth-form__password-input"
          type="password"
          ref="passwordRepeat"
          name="passwordRepeat"
          :label="$t('placeholders.repeatPassword')"
          :invalidFeedback="getErrorMessage('password') || getErrorMessage('passwordRepeat')"
          :state="
            ($v.form.password.$error ? false : !$v.form.password.$invalid ? true : null) &&
            ($v.form.passwordRepeat.$error ? false : !$v.form.passwordRepeat.$invalid ? true : null)
          "
          @blur="setField"
        />

        <b-form-group id="input-group-6" class="rules">
          <b-form-checkbox v-model="form.checked" :state="$v.form.checked.$error ? false : null">
            <span class="rules-text">
              {{ $t('supportText.iAgreeWithTheTerms') }}
              <br />
              <a :href="getUserAgreementLink" class="register-card__link" target="_blank">
                {{ $t('footer.userAgreement') }}</a
              ></span
            >
          </b-form-checkbox>
        </b-form-group>

        <b-button class="auth-btn mt-3" type="submit" variant="primary" :disabled="loading">
          <span v-if="!loading">{{ $t('buttonLabels.registration') }}</span>
          <span v-else>
            <b-spinner small type="grow"></b-spinner>
            {{ $t('label.processing') }}
          </span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  required, sameAs, email, helpers,
} from 'vuelidate/lib/validators';
import focusOnValidation from '@/mixins/focusOnValidation';
import AppInput from '@/components/AppInput.vue';
import AuthService from '@/services/auth.service';

const passwordMatch = helpers.regex('passwordMatch', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);

export default {
  name: 'RegisterForm',
  components: { AppInput },
  mixins: [focusOnValidation],
  data: () => ({
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      checked: true,
    },
    emailError: '',
    serverError: '',
    error: null,
    loading: false,
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        passwordMatch,
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password'),
      },
      checked: {
        sameAs: sameAs(() => true),
      },
    },
  },
  computed: {
    validation() {
      return this.form.lastName.length > 4 && this.form.lastName.length < 13;
    },
    getUserAgreementLink() {
      if (this.$i18n.locale === 'en') {
        return `${this.publicPath}user-agreement-ru.pdf`;
      }
      return `${this.publicPath}user-agreement-ru.pdf`; // replace when en-version ready
    },
  },
  methods: {
    setField(e) {
      const fieldName = e.target.name;
      this.form[fieldName] = e.target.value;
      this.$v.form[fieldName].$touch();
      this.resetCustomErrors();
    },

    resetCustomErrors() {
      this.emailError = '';
      this.serverError = '';
    },
    async onSubmit(event) {
      event.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const registerInput = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        username: this.form.email,
        password: this.form.password,
      };
      this.loading = true;
      this.error = null;

      try {
        await this.$store
          .dispatch('auth/registration', {
            registerInput,
          })
          .then(() => AuthService.sendConfirmEmail(this.$i18n.locale));
        const subscribeLink = localStorage.getItem('subscribe-link');
        const subscribeManager = localStorage.getItem('subscribe-manager');
        if (subscribeLink) {
          localStorage.removeItem('subscribe-link');
          await this.$router.push(subscribeLink);
        } else if (subscribeManager) {
          const { id, token } = JSON.parse(subscribeManager);
          localStorage.removeItem('subscribe-manager');
          await this.$router.push({ name: 'SubscribeManager', params: { id }, query: { token } });
        } else {
          this.$router.push('/programs').catch(() => {});
        }
      } catch (error) {
        if (error.response?.status === 400) {
          this.error = this.$t('errorMessages.emailBusy');
        } else {
          this.error = this.$t('errorMessages.somethingWentWrong');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.auth-btn {
  min-width: 11.5rem;
}

::v-deep .form-group {
  margin-bottom: 1.25rem;
}

::v-deep input.form-control:focus {
  color: #f2f2f2;
  box-shadow: none;
}

::v-deep .btn {
  margin: 0 !important;
  padding: 0.875rem 4.458rem;
  max-width: 300px;
}
</style>
